import { combineReducers } from "redux";
import { AppReducer, RouteReducer } from "./app";
import { AuthReducer, PermissionMenuReducer, PermissionModulesReducer } from "./auth";

const rootReducer = combineReducers({
    app: AppReducer,
    auth: AuthReducer,
    permissionMenu: PermissionMenuReducer,
    permissionModules: PermissionModulesReducer,
    router: RouteReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;