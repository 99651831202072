import ReactDOM from 'react-dom'
import {Provider} from 'react-redux'

// Apps
import {MetronicI18nProvider} from './_metronic/i18n/Metronici18n'
/**
 * TIP: Replace this style import with dark styles to enable dark mode
 *
 * import './_metronic/assets/sass/style.dark.scss'
 *
 * TIP: Replace this style import with rtl styles to enable rtl mode
 *
 * import './_metronic/assets/css/style.rtl.css'
 **/
import './_metronic/assets/sass/style.scss'
import './_metronic/assets/sass/style.react.scss'
import {AppRoutes} from './app/routing/AppRoutes'
import store from './app/redux/store'

import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify'

ReactDOM.render(
  <MetronicI18nProvider>
    <Provider store={store}>
      <ToastContainer />
      <AppRoutes />
    </Provider>
  </MetronicI18nProvider>,
  document.getElementById('root')
)
