import { IAction } from "../store";
import { ERROR_PERMISSION_MODULES, LOADING_PERMISSION_MODULES, SUCCESS_PERMISSION_MODULES } from "./PermissionModulesActionTypes";

export interface IPermissionModulesState {
    data: any[];
    status: 'pending' | 'loading' | 'success' | 'error';
    error?: string;
}

const InitialState:IPermissionModulesState = {
    data: [],
    status: 'pending'
}

const PermissionModulesReducer = (state = InitialState, action: IAction) => {
    let newState = {...state};
    switch(action.type){
        case LOADING_PERMISSION_MODULES:
            newState.status = 'loading';
            break;
        case SUCCESS_PERMISSION_MODULES:
            newState.status = 'success';
            newState.data = action.payload.data;
            break;
        case ERROR_PERMISSION_MODULES:
            newState.status = 'error';
            newState.error = action.payload.error;
            break;
        default:
    }
    return newState;
}

export default PermissionModulesReducer;