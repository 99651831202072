import axios, { AxiosRequestConfig } from "axios";

const isProduction = process.env.REACT_APP_BUILD_MODE === 'PRODUCTION';
const apikey = process.env.REACT_APP_API_KEY;
const auth = 'Basic ' + btoa(process.env.REACT_APP_USERNAME + ':' + process.env.REACT_APP_PASSWORD);
const strapiToken = process.env.REACT_APP_TOKEN_STRAPI_EXTRA;

// default config for connection to gcp
export const CONFIG = (config: AxiosRequestConfig) => {
    if(!config.headers) config.headers = {};
    if(isProduction && apikey){
        config.headers.apikey = apikey;
        config.headers.Authorization = auth;
    }
    const session_id = localStorage.getItem('session_id');
    if(session_id){
        config.headers.session_id = session_id;
    }
    config.headers.dc = 'sg';
    return config;
}

// config into legacy platform
export const CONFIG_CP = (config: AxiosRequestConfig) => {
    config.method = 'POST'; // overide all method into post
    if(!config.data || config.data !instanceof FormData){ // check if no data or data is not FormData
        config.data = new FormData();   // change to new form data
    }
    // need to append internal giftano each request,
    // not needed if using proxy cloudflare
    config.data.append('custom_id', 'internal_giftano'); 
    return config;
}

export const CONFIG_STRAPI_CONTENT = (config: AxiosRequestConfig, token:string) => {
    config.headers.Authorization = 'Bearer ' + token;
    return config;
}

// config connection into strapi extra (heroku)
export const CONFIG_STRAPI_EXTRA = (config: AxiosRequestConfig) => {
    config.headers.Authorization = 'Bearer ' + strapiToken;
    return config;
}

export const CONNECTION = async (config: AxiosRequestConfig) => {
    try{
        const res = await axios(config);
        if(res.status === 200){
            const data = res.data;
            return data;
        }
    }catch(err: any){
        // handle error here
        throw err;
    }
}