import { CONFIG, CONNECTION } from "../../helper";

const appName = process.env.REACT_APP_NAME;
const endpoint = process.env.REACT_APP_ENDPOINT_AUTH;

export const getPermissionMenus = async() => {
    const config = CONFIG({
        url: endpoint + '/permission-menus/' + appName + '/undefined'
    });
    return await CONNECTION(config);
}

export const getPermissionModules = async() => {
    const config = CONFIG({
        url: endpoint + '/permission-modules/' + appName + '/undefined'
    });
    return await CONNECTION(config);
}

export const postResetPassword = async(email:string) => {
    const fd = new FormData();
    fd.append("action", "sendkey");
    fd.append("email", email);

    const config = CONFIG({
        url: endpoint + '/reset-password',
        method: 'POST',
        data: fd     
    });
    return await CONNECTION(config);
}

export const logout = async() => {
    const config = CONFIG({
        url: endpoint + '/logout',
        method: 'POST'
    });
    return await CONNECTION(config);
}