import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { RootState } from '../../../../app/redux/rootReducer'
import {MenuItem} from './MenuItem'
// import {useIntl} from 'react-intl'

export function MenuInner() {
  const dataMenu:any[] = useSelector((state:RootState) => state.permissionMenu.data, shallowEqual);
  //restructure json menu
  const getChildren: any = (id:string) => {
    return dataMenu
      .filter((i) => i.parent_id === id)
      .map((item) => ({
        ...item,
        childrens: getChildren(item.menu_id),
      }));
  };
  let menu = getChildren(
    dataMenu.filter((i) => i.name === "top_menu").map((i) => i.menu_id)[0]
  );
  
  return (
    <>
      {/* <MenuItem title={intl.formatMessage({id: 'MENU.DASHBOARD'})} to='/dashboard' /> */}
      {menu.map((item:any) => (
        <MenuItem key={'menu_item_' + item.id} title={item.name} to={item.url} />
      ))}
    </>
  )
}
