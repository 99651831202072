import { IAction } from "../store";
import { ERROR_ROUTE, LOADING_ROUTE, SUCCESS_ROUTE } from "./RouteActionTypes";

export interface IRouteState {
    data: any[];
    status: 'pending' | 'loading' | 'success' | 'error';
    error?: string;
}

const initialState: IRouteState = {
    data: [],
    status: 'pending'
}

const RouteReducer = (state = initialState, action:IAction) => {
    let newState = {...state};
    switch(action.type){
        case LOADING_ROUTE:
            newState.status = 'loading';
            break;
        case SUCCESS_ROUTE:
            newState.status = 'success';
            newState.data = action.payload.data || [];
            break;
        case ERROR_ROUTE:
            newState.status = 'error';
            newState.error = action.payload.error;
            break;
        default:
    }
    return newState;
}

export default RouteReducer;