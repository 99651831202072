import { toast } from "react-toastify";
import { Dispatch } from "redux";
import { log } from "../../helper";
import { getPermissionModules } from "../../services";
import { IAction } from "../store";
import { ERROR_PERMISSION_MODULES, LOADING_PERMISSION_MODULES, SUCCESS_PERMISSION_MODULES } from "./PermissionModulesActionTypes";

export const getPermissionModulesAct = () => async (dispatch: Dispatch<IAction>) => {
    dispatch({type: LOADING_PERMISSION_MODULES});
    try{
        let res = await getPermissionModules();
        if(res.code === 200){
            dispatch({type: SUCCESS_PERMISSION_MODULES, payload: {data: res.modules || []}});
        }else{
            toast.warning(res.message || 'Oops something wrong while get permission menu data, please reload to try again!');
            dispatch({type: ERROR_PERMISSION_MODULES, payload: {error: res.error}})
        }
    }catch(err){
        log('error load module',err);
        dispatch({type: ERROR_PERMISSION_MODULES, payload: {error: err}});
    }
}