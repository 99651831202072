import { PageDataProvider } from './core'
import { Content } from './components/Content'
import { Outlet } from 'react-router-dom'

const BlankLayout = () => {
    return (
        <PageDataProvider>
            <div className='page'>
                <Content>
                    <Outlet />
                </Content>
            </div>
        </PageDataProvider>
    )
}

export { BlankLayout }
