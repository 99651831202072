import { CONFIG, CONNECTION } from "../../helper";

const appName = process.env.REACT_APP_NAME;
const endpoint = process.env.REACT_APP_ENDPOINT_PROFILE;

export const getProfile = async () => {
    const config = CONFIG({
        url: endpoint + '/get/' + appName
    });
    return await CONNECTION(config);
}