import {FC, useEffect, useState} from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { getRoutesAct } from '../../redux/app/RouteAction'
import { getPermissionMenuAct, getPermissionModulesAct, setSessionId } from '../../redux/auth'
import { getProfileAct } from '../../redux/auth/AuthActions'
import { RootState } from '../../redux/rootReducer'

const AuthInit: FC = ({children}) => {
  const [showSplashScreen, setShowSplashScreen] = useState(true)
  const session_id = useSelector((state: RootState) => state.auth.session_id);
  const profile:any = useSelector((state: RootState) => state.auth.profile, shallowEqual);
  const routeStatus = useSelector((state: RootState) => state.router.status);
  const permissionMenuStatus = useSelector((state: RootState) => state.permissionMenu.status);
  const permissionModulesStatus = useSelector((state: RootState) => state.permissionModules.status);
  const dispatch = useDispatch();

  useEffect(() => {
    // init auth here
    if(!session_id){
      // try to get from param
      const params = new URLSearchParams(window.location.search);
      const paramSessionId = params.get('session_id');
      if(paramSessionId){
        // remove param from url and dispatch session id 
        window.history.pushState('', '', window.location.origin + window.location.pathname);
        dispatch(setSessionId(atob(paramSessionId)));
      }else{
        // dont have session id, redirect to login
        const redirectUrl = window.location.origin + window.location.pathname + '|' + process.env.REACT_APP_NAME;
        window.location.href = process.env.REACT_APP_AUTH_URL + '?redirect=' + btoa(redirectUrl); 
      }
    }else{
      dispatch(getProfileAct())
    }
  }, [session_id, dispatch]);

  useEffect(()=>{
    if(profile && profile.id){
      // session are valid need to load route and permission menu and permission module
      if(routeStatus === 'pending'){
        dispatch(getRoutesAct());
      }
      if(permissionMenuStatus === 'pending'){
        dispatch(getPermissionMenuAct());
      }
      if(permissionModulesStatus === 'pending'){
        dispatch(getPermissionModulesAct());
      }
      if(routeStatus === 'success' && permissionMenuStatus === 'success'){
        setShowSplashScreen(false);
      }
    }
  },[profile, routeStatus, permissionMenuStatus, permissionModulesStatus, dispatch])

  return showSplashScreen ? <SplashScreen/> : <>{children}</>
}

const SplashScreen = () => (
  <div className='splash-screen'>
    <img src="https://static-cdn.giftano.com/assets/giftano-logo-horizontal.png" alt="Logo" height={60}/>
    <span>Authenticating ...</span>
  </div>
)

export default AuthInit
