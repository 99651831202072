/* eslint-disable react/jsx-no-target-blank */
import { shallowEqual, useSelector } from 'react-redux'
import { RootState } from '../../../../app/redux/rootReducer'
import {AsideMenuItem} from './AsideMenuItem'
import { AsideMenuItemWithSub } from './AsideMenuItemWithSub'

export function AsideMenuMain() {
  const dataMenu:any[] = useSelector((state: RootState) => state.permissionMenu.data, shallowEqual)
    .sort((a,b) => a.order - b.order);
  
  //restructure json menu
  const getChildren: any = (id:string) => {
    return dataMenu
      .filter((i) => i.parent_id === id)
      .map((item) => ({
        ...item,
        childrens: getChildren(item.menu_id),
      }));
  };
  let menu = getChildren(
    dataMenu.filter((i) => i.name === "main_menu").map((i) => i.menu_id)[0]
  );
  
  const renderMenuItem = (item:any) => {
    if(item.childrens && item.childrens.length > 0){
      return(
        <AsideMenuItemWithSub
          key={'menu_item_' + item.id}
          to={item.url}
          title={item.name}
          icon={item.icon}
        >
          {item.childrens.map((subMenuItem:any) => renderMenuItem(subMenuItem))}
        </AsideMenuItemWithSub>
      )
    }else{
      return(
        <AsideMenuItem
          key={'menu_item_' + item.id}
          to={item.url}
          title={item.name}
          icon={item.icon}
        />
      )
    }
  }

  return (
    <>
      {menu.map((menuItem:any) => renderMenuItem(menuItem))}

      <div className='menu-item'>
        <div className='menu-content'>
          <div className='separator mx-1 my-4'></div>
        </div>
      </div>
    </>
  )
}
