import { IAction } from "../store";
import { AUTH_LOGGED_IN, AUTH_LOGGED_OUT, AUTH_LOGIN, AUTH_LOGOUT, AUTH_SET_SESSION_ID } from "./AuthActionTypes";

export interface IAuthState {
    status: 'pending' | 'log-in' | 'logged-in' | 'log-out' | 'logged-out' | 'error';
    profile?: any;
    session_id?: string | null;
    strapi_token?: string;
    error?: string;
}

const initialState: IAuthState = {
    status: 'pending',
    session_id: localStorage.getItem('session_id')
}

const AuthReducer = (state = initialState, action: IAction) => {
    let newState: IAuthState = {...state};
    switch(action.type){
        case AUTH_SET_SESSION_ID:
            newState.session_id = action.payload.session_id;
            localStorage.setItem('session_id', action.payload.session_id);
            break;
        case AUTH_LOGIN:
            newState.status = 'log-in';
            break;
        case AUTH_LOGGED_IN:
            newState.status = 'logged-in';
            newState.profile = action.payload.profile;
            break;
        case AUTH_LOGOUT:
            newState.status = 'log-out';
            break;
        case AUTH_LOGGED_OUT:
            newState.status = 'logged-out';
            newState.session_id = null;
            newState.profile = {};
            localStorage.removeItem('session_id');
            break;
        default:
    }
    return newState;
}

export default AuthReducer;