import { Dispatch } from "redux";
import { getProfile, logout } from "../../services";
import { AUTH_ERROR, AUTH_LOGGED_IN, AUTH_LOGGED_OUT, AUTH_LOGIN, AUTH_LOGOUT, AUTH_SET_SESSION_ID } from "./AuthActionTypes";
import { toast } from 'react-toastify';
import { IAction } from "../store";
import { log } from "../../helper";

const appName = process.env.REACT_APP_NAME || '';

export const getProfileAct = () => async (dispatch: Dispatch<IAction>) => {
    dispatch({type: AUTH_LOGIN});
    try{
        let res = await getProfile();
        if(res.code === 200 && res.data[appName]){
            dispatch({type: AUTH_LOGGED_IN, payload: {profile: res.data[appName]}});
        }else{
            toast.warning('Oops something wrong get profile data, please try again!', {
                autoClose: false,
                onClose: ()=> dispatch({type: AUTH_LOGGED_OUT})
            });
        }
    }catch(err:any){
        log(err);
        if(err && err.response && err.response.status === 401){
            toast.error('Oops your session is invalid or expired. Please login again!',{
                autoClose: false,
                onClose: ()=> dispatch({type: AUTH_LOGGED_OUT})
            });
        }else{
            dispatch({type: AUTH_ERROR, payload: {error: err}})
        }
    }
}

export const setSessionId = (session_id: string) => async (dispatch: Dispatch) => {
    dispatch({type: AUTH_SET_SESSION_ID, payload: { session_id: session_id }});
}

export const logoutAct = () => async (dispatch:Dispatch) => {
    dispatch({type: AUTH_LOGOUT});
    try{
        let res = await logout();
        if(res.code === 200){
            toast.success('Logout successfully, you will be redirected to login page!', {
                delay: 1000,
                onClose: ()=>{
                    dispatch({type: AUTH_LOGGED_OUT});
                }
            })
        }else{
            toast.warning('Failed to logout, please try again!',{
                autoClose: false,
                onClose: () => {
                    window.location.reload();
                }
            })
        }
    }catch{
        toast.error('Oops something wrong while logout, please try again!');
    }
}